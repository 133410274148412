@import "_variables";
html{
  font-size: 100%;
  @media(max-width: 767px){
    font-size: 90%;
  }
}
body{
    font-family: 'Open Sans', sans-serif!important;
    background-color: #F8F8F8;
}
.object-fit-cover{
  object-fit: cover;
  height:100%;
  width:100%;
}
.vert-text{
  top: 50%;
position: absolute;
  @media(max-width:767px){
    display: none;
  }
  h2{
    
    color: $secondary_color;
    font-family: $header_font;
    font-size: 1.75rem;
    font-weight: bold;
    @media(min-width:768px){
      min-width: 300px;
      position: absolute;
      top:50%;
      transform: rotate(270deg);
      transform-origin: left top 0;
    }
  }
}

@media(min-width:767px){
  .same-height{
    .row {
      display: table;
    }

    [class*="col-"] {
        float: none;
        display: table-cell;
        vertical-align: top;
    }
  }
}

.header-v1{
  background-color: #FFFFFF!important;
  .button-container{
    top:25px;
  }
  .action-buttons{
    margin-top:20px;
  }
  .main-navigation{ 
    padding: 25px 0;
    li{ 
      a{
        color: $body_text_color;
        font-size: 1rem;
        font-weight: bold;
        text-decoration: none;
        text-transform: uppercase;
      }
    }
  }
}
.title-image-back{
  .tag{
    h1{
      font-size: 3rem;
    }
  }
}
a{
  color: $primary_color;
  text-decoration: underline;
  font-weight: 500;
}
p{
  font-size: 1.1rem;
  line-height: 1.4rem;
}
h1{
  font-size: 5rem;
  font-weight: bold;
}
h2{
  font-size: 2.2rem;
  font-weight: bold;
}
h3{
  font-size: 2rem;
  font-weight: bold;
}
h4{
  font-family: $global_font;
  font-weight: bold;
}
.btn{
  border-width: 2px;
  border-radius: 0;
  padding: 10px 25px;
  text-decoration: none;
  span{
    font-weight: bold;
    font-size: 1rem;
  }
  
}
.btn-outline{
  font-family: $global_font;
  border-color: $primary_color;
  color: $primary_color;
  background-color: transparent;
  &:active{
    font-family: $global_font;
    border-color: $primary_color;
    color: $primary_color;
    background-color: transparent;
  }
  span{
    font-size: 1rem;
    text-transform: uppercase;
  }
  &:hover{
    background-color: $primary_color;
    color: #F8F8F8;
  }
}
.btn-solid{
  background-color: #EDEDDF;
  color: $primary_color;
  border-color: #EDEDDF;
  &:active{
    background-color: #EDEDDF;
    color: $primary_color;
    border-color: #EDEDDF;
  }
  span{
    font-size: 1rem;
    text-transform: uppercase;
  }
  &:hover{
    background-color: $primary_color;
    color: #F8F8F8;
    border-color: $primary_color;
  }
}

.full-screen-billboard{

  &.light{
    h2{
      text-shadow: none;
    }
    h3{
      text-shadow: none;
    }

  }
  h2{
    text-shadow: none;
    font-size: 5rem;
    font-family: $header_font;
    margin: 0;
    @media(max-width: 767px){
      font-size: 3.5rem;
    }
  }
  h3{
    text-shadow: none;
    font-family: $global_font;
    font-size:1.4rem;
    line-height: 1.9rem;
    margin: 0;
  }
  .action-buttons{
    margin-left: 0;
  }
}

#tabbedCarousel{
  margin-top:50px;
  margin-bottom: 50px;
  .row {
    display: table;
}

[class*="col-"] {
    float: none;
    display: table-cell;
    vertical-align: top;
}
  .carousel-inner{
    .item{
      height:100%;
      img{
        height:100%;
        object-fit: cover;
      }
    }
  }
  .nav{
    border-left:1px $body_text_color solid;
    border-right: 1px $body_text_color solid;
    padding: 0 25px;
    li{
      cursor:pointer;
      text-align: center;
      border-top:1px $body_text_color solid;
      &:first-child{
        border-top:none;
      }
      font-family:$header_font;
      font-weight: bold;
      padding: 20px 0;
      &:hover{
        h2{
          color: $primary_color;
        }
      }
      h2{
        color: $body_text_color;
        font-size: 1.6rem;
        margin-bottom: 5px;
      }
      &.active{
        h2{
          color: $primary_color;
        }
        .tab-link{
          display:block;
          opacity:1;
        }
      }
      .tab-link{
        display:none;
        opacity:0;
        &:hover{
          background-color: transparent;
        }
      }
    }
  }
}

.scrolling-news{
  padding-top: 50px;
  padding-bottom: 50px;
  overflow-x: hidden;
  .row {
    display: table;
  }

  [class*="col-"] {
      float: none;
      display: table-cell;
      vertical-align: top;
  }
  .scrolling-wrapper-flex {
    display: flex;
    flex-wrap: nowrap;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    &::-webkit-scrollbar {
      display: none;
    }
    .card {
      flex: 0 0 auto;
      max-width:50%;
      text-align: center;
      padding: 0 25px;
      
      .content{
        background-color:#FFFFFF;
        padding:15px 20px;
      }
      .date{
        font-size: .85rem;
        color: $primary_color;
        padding-bottom: 10px;
        padding-top:20px;
        background: url('/wp-content/themes/arschopp-theme/img/line.png') bottom center no-repeat;
      }
      h2{
        color: #2B2B2B;
        font-size: 1.5rem;
        font-weight: bold;
      }
      img{
        width:100%;
      }
    }
  }
}
.featherlight-gallery{
  .gallery-image{
    float: left;
    margin-bottom: 15px;
  }
}
.featherlight{ 
  .featherlight-content{
    border:5px #F8F8F8 solid;
    padding:0;
  }
}
.featherlight-next, .featherlight-previous{
  display: block;
  span{
    text-shadow: none;
    display: inline-block!important;
    font-size: 3rem;
  }
  &:hover{
    background:none;
  }
}


.product-block{
  .pipe-separator{
    @media(max-width:767px){
      display: none;
    }
  }
  height:100%;
  .content-block{
 
  }
  &.alignment-left{
    .product-block-2{
      .content-block{
        @media(max-width: 768px){
          padding:15px 0;
        }
        .title{
          @media(min-width: 768px){
            margin-left:25%;
          }
        }
      }
    }
  }
  &.alignment-right{
    .product-block-2{
      .content-block{
        @media(max-width: 768px){
          padding:15px 0;
        }
        .title{
          @media(min-width: 768px){
            margin-left:-25%;
          }
        }
      }
    }
  }
  .product-block-2{
    .content-block{
      background-color: transparent;
      width:100%;
      .title{
        width:100%;
        background-color: #FFFFFF;
        padding: 10px;
        text-align: center;
      }
    }
  }
  .content-block{
    background-color: #fff;
    padding: 15px 25px;
    @media(max-width: 767px){
    
    }
    position: relative;
    width: 125%;
    @media(max-width: 991px){
      width:100%;
    }
    z-index: 100;
    &.right{
      @media(min-width: 768px){
        margin-left:-25%;
      }
    }
    

    h2{
      font-family: $header_font;
      font-size: 1.4rem;
      color: #2B2B2B;
    }
  }
}

.base-footer{
  background-color: #FFFFFF;
  padding:75px 0;
  .center-on-mobile{
    @media(max-width:991px){
      text-align: center;
      img{
        margin:auto;
      }
    }
  }
  @media(max-width:767px){
    padding:50px 0;
  }
  h3{
    color: #939393;
  }
  hr{
    border-bottom: 1px #979797 solid;
  }
  .bottom-row{
    .col-md-2, .col-md-7, .col-md-3{
      padding-top:15px;
    }
    .border-left-right{
      @media(min-width:992px){
      border-left: 1px #979797 solid;
      border-right: 1px #979797 solid;
      }
    }
    ul{
      padding-left:0;
      list-style-type:none;
      li{
        display: inline;
        a{
          text-decoration: none;
          text-transform: uppercase;
          padding: 0 5px;
          font-size: .9rem;
        }
      }
    }
    a,p{
      color:#939393;
      font-size:.9rem;
    }
  }
  .footer-bottom-nav{ 
    padding-bottom: 15px;
    padding-left:0;
    margin-left:0;
    
    li{
      display: inline-block;
      a{
        color: #939393;
        font-weight: bold;
        font-size: .9rem;
        text-transform: uppercase;
        padding:0 15px;
        text-decoration: none;
      }
      &:first-child{
        a{
          padding:0 15px 0 0;
        }
      }
    }
  }
  .footer-top-nav{
    padding-bottom: 15px;
    padding-left:0;
    margin-left:0;
    margin-bottom:0;
    border-bottom: 1px #979797 solid;
    @media(max-width: 991px){
     margin-top:15px;
    }
    li{
      display: inline-block;
      a{
        color: #939393;
        font-weight: bold;
        font-size: .9rem;
        text-transform: uppercase;
        padding:0 15px;
        @media(max-width: 767px){
          padding:0 5px;
        }
        text-decoration: none;
      }
      &:first-child{
        a{
          padding:0 15px 0 0;
        }
      }
    }
  }
}
